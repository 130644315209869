














import { Component, Vue } from 'vue-property-decorator'
import { GlobalGetters } from '@/shared/store/global/global.getters'
import { GlobalActions } from '@/shared/store/global/global.actions'
import { LOCALES } from '@/shared/constants/locales.constants'
import http from '@/shared/http'
import tokenHelper from '@/shared/helpers/token.helper'
import { AuthGetters } from '@/shared/store/auth/auth.getters'
import { getVersion } from '@/shared/helpers/version.helper'
import { getLanguagesByVersion } from '@/config'

@Component
export default class AuthMultiLanguage extends Vue {
  set currentLanguage(locale: string) {
    this.setLocale(locale)
  }

  get currentLanguage() {
    return this.$store.getters[GlobalGetters.getCurrentLocale]
  }

  async setLocale(locale: string) {
    if (! this.inLoginLikeUser && this.isLogged)
      await http.post('update-locale', { locale })

    this.$store.dispatch(GlobalActions.setLocale, locale)
  }

  getFlag(item: string): string | null {
    return LOCALES[item].flag
  }

  get items(): any[] {
    return getLanguagesByVersion()
  }

  get isLogged(): boolean {
    return this.$store.getters[AuthGetters.isLogged]
  }

  get inLoginLikeUser(): boolean {
    return tokenHelper.getOtherAccessTokens().length > 0
  }
}
