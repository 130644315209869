






















import { Component, Prop, Vue } from 'vue-property-decorator'
import Company from '@/shared/modules/company/company.model'
import { CompanyGetters } from '@/shared/store/company/company.getters'
import AuthMultiLanguage from '@/shared/components/AuthMultiLanguage.vue'
import __ from '@/shared/helpers/__'
import ChangeVersion from '@/shared/components/ChangeVersion.vue'

@Component({
  components: { ChangeVersion, AuthMultiLanguage },
  methods: { __ }
})
export default class AuthLayout extends Vue {
  @Prop() headline!: string

  isDevelopment(): boolean {
    return ! (window.location.hostname.indexOf('login.nsystem') !== -1)
  }

  get company(): Company | null {
    return this.$store.getters[CompanyGetters.getCompany]
  }
}
