














import { Component, Vue } from 'vue-property-decorator'
import { getVersion, setVersion } from '@/shared/helpers/version.helper'

@Component
export default class ChangeVersion extends Vue {
  versionTitles = {
    lt: 'Liet.',
    no: 'Nor.',
  }

  get currentVersion() {
    return getVersion()
  }

  set currentVersion(version: string) {
    setVersion(version)
    history.go(0)
  }

  get items(): any[] {
    return ['lt', 'no']
  }
}
